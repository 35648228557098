body {
  margin: 0;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 0px;
    }
}


// html::-webkit-scrollbar {
//   width: 10px;
// }

// html::-webkit-scrollbar-track {
//   background: rgba(0, 0, 0, 0.441);
// }

// html::-webkit-scrollbar-thumb {
//   background: rgba(249, 249, 249, 0.967);
// }

.cursor-pointer {
  cursor: pointer;
}
