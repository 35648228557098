// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Inter, sans-serif !important;
  // font-weight: 400;
  scroll-behavior: smooth;
}
body {
  * {
    font-family: Inter !important;
    scroll-behavior: smooth;
  }
}

@import './variables';
// @import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/bootstrap-utilities.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/spinners';

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
// @import "~bootstrap/scss/tables";

@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
// @import "~bootstrap/scss/button-group";
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
// @import "~bootstrap/scss/accordion";
@import '~bootstrap/scss/breadcrumb';
// @import "~bootstrap/scss/pagination";
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
// @import "~bootstrap/scss/list-group";
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
// @import "~bootstrap/scss/carousel";
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/placeholders';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

@import url(../../Components/Banner/_banner.scss);
@import url(../../Components/AuthForm/_authForm.scss);
@import url(../../Pages/Auth/Login/_login.scss);
@import url(../../Components/Input/_input.scss);
@import url(../../Components/Button/_button.scss);
@import url(../../Assets/Style/_auth.scss);

@import './_variables';
@import './global';
@import './modal';

@import url(../../Components/Navbar/_navbar.scss);
@import url(../../Components/Sidebar/_sidebar.scss);
@import url(../../Layout/MainLayout/_mainLayout.scss);
@import url(../../Components/Page-Title/_pageTitle.scss);

::selection {
  background: $primary;
  color: $white;
}
