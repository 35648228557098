.wrapper_section {
  background-color: white;
  position: relative;
  height: auto;
  padding: 20px;
  margin: 20px 20px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(178, 191, 210, 0.344);
  // position: relative;
}

.transparent {
  padding: 20px 0;
  background-color: transparent;
  margin: 0px 20px;
  box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
  // position: relative;
}

.wrapper-loader {
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.608);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: progress;
}

.subscription-details {
  background-color: rgba(0, 0, 0, 1);
  color: #FFF;
  background-image: linear-gradient(135deg, rgba(255, 0, 0, 0.5), rgba(45, 2, 2, 0));

  label {
    font-weight: 600;
    color: #FFF;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
  }
}

.subscription-section {
  .wrapper_section {
    margin-left: 0;
    margin-right: 0;
  }
}